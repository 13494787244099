import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigurationState } from '@app/shared';
import { memoize } from 'lodash-es';
import { UserConfiguration } from '@app/configuration/configuration.model';
import { ConfigurationValue } from '@wap/configuration';

const getConfigurationFeatureState = createFeatureSelector<ConfigurationState>('configuration');

const getConfiguration = createSelector(
  getConfigurationFeatureState,
  (state) => state.configuration,
);

const getCourseConfiguration = createSelector(
  getConfigurationFeatureState,
  (state) => state.courseConfiguration,
);

const getUserConfiguration = memoize(<T = ConfigurationValue>(key: keyof UserConfiguration) =>
  createSelector(getConfigurationFeatureState, (state) => state.configuration?.[key] as T),
);

const isEnabled = memoize((key: string) =>
  createSelector(getConfigurationFeatureState, (state) => state.configuration?.[key] === true),
);

export const configurationSelectors = {
  getConfiguration,
  getCourseConfiguration,
  getUserConfiguration,
  isEnabled,
};
