import { AggregateTopic } from './aggregate.model';

export interface PersistedAggregate {
  activityId: string;
  actor: string;
  group: string;
  id: string;
  isRead: boolean;
  isSeen: boolean;
  key: string;
  object: string;
  origin: string;
  target: string;
  topic: AggregateTopic;
  updated: Date;
  card: AchievementCard;
}

export interface AchievementCard {
  title: string;
  date: Date;
  objectType: string;
  source?: string;
  body?: string;
  topic?: AchievementCardTopic;
  items?: WeeklyUsageItem[];
  scope?: {
    fromDate: Date;
    toDate: Date;
  };
}

export interface WeeklyUsageItem {
  max: number;
  measure: 'minutes' | 'days';
  min: number;
  objectType: 'donut' | 'segmented-progress';
  topic: 'ems' | 'erp' | 'ccn';
  value: number;
}

export enum AchievementCardTopic {
  SMART_START = 'Smartstart',
  READING = 'Reading',
  MATH_AND_SCIENCE = 'Math and Science',
}
