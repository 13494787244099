import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from '../state/settings.state';

const getSettingsFeatureState = createFeatureSelector<SettingsState>('settings');

const getSettings = createSelector(getSettingsFeatureState, (state) => state.settings);

const getDashboardsUrl = createSelector(getSettings, (settings) => settings?.dashboards?.url);

const getApiSettings = createSelector(getSettings, (settings) => settings.api);

const getUserType = createSelector(getSettingsFeatureState, (state) => state.userType);

const getUpstartUserLogin = createSelector(
  getSettingsFeatureState,
  (state) => state.upstartUserLogin,
);

const getJasperUrl = createSelector(getSettings, (settings) => settings?.reporting?.jasperUrl);

const getContentUrl = createSelector(getSettings, (settings) => settings?.content?.url);

const getContentV3Url = createSelector(getSettings, (settings) => settings?.contentV3?.url);

const getCloudFrontUrl = createSelector(
  getSettings,
  (settings) => settings?.content?.cloudFrontUrl,
);

const getVideoTemplateId = createSelector(
  getSettings,
  (settings) => settings?.content?.videoTemplateId,
);

const getResourcesUrl = createSelector(getSettings, (settings) => settings?.resources?.url);

const getFusionSettings = createSelector(getSettings, (settings) => settings?.fusion);

const getHelpMessages = createSelector(getSettings, (settings) => settings?.helpMessages);

const getSentrySettings = createSelector(getSettings, (settings) => settings?.sentry);

const getUpstartRegistrationSettings = createSelector(
  getSettings,
  (settings) => settings?.upstartRegistration,
);

const websocketUrl = createSelector(
  getSettings,
  (settings) => settings?.notifications?.websocketUrl,
);

const isGoogleAnalyticsEnabled = createSelector(
  getSettings,
  (settings) => settings?.googleAnalytics?.enabled,
);

const getSignUpUrl = createSelector(getSettings, (settings) => settings?.upstartLogin?.signUpUrl);

const isUpstartLoginEnabled = createSelector(
  getSettings,
  (settings) => settings?.upstartLogin?.enabled,
);

export const settingsSelectors = {
  getSettings,
  getDashboardsUrl,
  getApiSettings,
  getUserType,
  getUpstartUserLogin,
  getJasperUrl,
  getContentUrl,
  getContentV3Url,
  getCloudFrontUrl,
  getVideoTemplateId,
  getResourcesUrl,
  getFusionSettings,
  getHelpMessages,
  getSentrySettings,
  getUpstartRegistrationSettings,
  websocketUrl,
  isGoogleAnalyticsEnabled,
  getSignUpUrl,
  isUpstartLoginEnabled,
};
