import { AchievementCardTopic, WeeklyUsageItem } from './persisted-aggregate.model';

export enum AggregateTopic {
  ACHIEVEMENT = 'achievement',
  USAGE = 'usage',
  ANNOUNCEMENT = 'announcement',
  MESSAGE = 'message',
  WEEKLY_USAGE = 'weekly_usage', // topic only exists in UI data for QOL
}

export interface Aggregate {
  key: string;
  user: string;
  id: string;
  aggregateId: string;
  topic: AggregateTopic;
  title: string;
  updated: Date;
  isRead: boolean;
  source?: string;
  body?: string;
  cardTopic?: AchievementCardTopic;
  items?: WeeklyUsageItem[];
}
