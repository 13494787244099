export enum Product {
  curriculet = 'curriculet',
  ems = 'ems',
  erp = 'erp',
  smartstart = 'smartstart',
  wacs = 'wacs',
  ccn = 'ccn',
  upstart = 'upstart',
  ayla = 'ayla',
}

export const PRODUCT_DISPLAY_NAME = {
  [Product.curriculet]: 'Curriculet',
  [Product.ems]: 'Early Math & Science',
  [Product.erp]: 'Early Reading',
  [Product.smartstart]: 'SmartStart',
  [Product.wacs]: 'Assessments',
  [Product.ccn]: 'Camp Consonant',
  [Product.upstart]: 'Upstart',
};

export const ProductDisplayOrder = {
  [Product.smartstart]: 0,
  [Product.erp]: 1,
  [Product.ems]: 2,
  [Product.curriculet]: 3,
  [Product.ccn]: 4,
  [Product.upstart]: 5,
  [Product.wacs]: 6,
};
