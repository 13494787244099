import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessagesAndNotificationsState } from '../state/messages-and-notifications.state';

const getNotificationsFeatureState = createFeatureSelector<MessagesAndNotificationsState>(
  'messages-and-notifications',
);

const getNotifications = createSelector(
  getNotificationsFeatureState,
  (state) => state.notifications,
);

const getLoading = createSelector(getNotificationsFeatureState, (state) => state.loading);

export const messagesAndNotificationsSelectors = {
  getNotifications,
  getLoading,
};
