export enum UserType {
  UNKNOWN = 'UNKNOWN',
  STUDENT = 'STUDENT',
  PARENT = 'PARENT',
  TEACHER = 'TEACHER',
  ADMIN = 'ADMIN',
  UPSTART = 'UPSTART',
}

export enum UserRole {
  UPSTART_PARENT = 'upstart-parent',
  CLASS_ADMIN = 'class-admin',
  SCHOOL_ADMIN = 'school-admin',
  DISTRICT_ADMIN = 'district-admin',
  INTERNAL_ADMIN = 'internal-admin',
  CURRICULUM_ADMIN = 'curriculum-admin',
  ACCOUNT_ADMIN = 'account-admin',
  SUPPORT_ENGINEER = 'support-engineer',
  PRODUCT_ADMIN = 'product-admin',
  PLAYLIST_ADMIN = 'playlist-admin',
}

export interface User {
  idToken: string;
  refreshToken?: string;
  name: string;
  cognitoUsername?: string;
  preferredName?: string;
  type: UserType;
  application: string;
  uuid?: string;
  organization?: string;
  grade?: number;
  school?: string;
  classroom?: string;
  email?: string;
  cognitoId: string;
  roles?: UserRole[];
  providerName?: string;
}
