import { Product } from './product.model';
import { AssignmentTimes, Method } from '@wap/catalog-v5';

export interface AssignmentDetails {
  products?: Product[];
  sessionTime?: number;
}

export interface AssignmentConfig {
  startDate: AssignmentTimes | string;
  endDate: AssignmentTimes | string;
  assignmentMethod: {
    method: Method;
    documentIds?: string[];
  };
}

export const DEFAULT_SESSION_TIME_SECONDS = 900;
export const COURSE_ASSIGNMENT_ID_PREFIX = 'wel_';
export const COURSE_DOCUMENT_ID_PREFIX = 'wel-wel_';

export const assignmentId = (suffix: string, product?: Product) =>
  `${COURSE_ASSIGNMENT_ID_PREFIX}${suffix}`;
export const documentId = (suffix: string, product?: Product) =>
  `${COURSE_DOCUMENT_ID_PREFIX}${suffix}`;
