import { PeriodStatus } from './period-status.type';

export interface AnalyticsFacet {
  [key: string]: string[];
}

export interface AnalyticsClassroom {
  className: string;
  classUuid: string;
  schoolName: string;
  schoolUuid: string;
}

export interface ClassroomAnalyticsFacet {
  classes: AnalyticsClassroom[];
}

export enum GoalStatus {
  ON_TRACK = 'ON TRACK',
  SURPASSING = 'SURPASSING GOAL',
  ACTION_TAKEN = 'ACTION TAKEN',
  NEEDS_ACTION = 'NEEDS ACTION',
}

export interface Objective {
  objName: string;
  score: number;
  mastery: number;
  mastered: boolean;
  currentWeek: boolean;
  currentDay: boolean;
}

export interface DashboardsDocument {
  id: string;
  documentType: string;
  organization: { uuid: string; name: string };
  classes: AnalyticsClassroom[];
  gradeLevel: string;
  uuid: string;
  identifier: string;
  givenName: string;
  familyName: string;
  fullName: string;
  gender: string;
  primaryLanguage: string;
  goalStatus: GoalStatus;
  currentDaySessionMinutes: number;
  currentDayGoalMinutes: number;
  currentWeekSessionMinutes: number;
  currentWeekGoalMinutes: number;
  currentWeekDaysActive: number;
  currentWeekDaysGoal: number;
  currentDayObjectivesCompleted: number;
  currentWeekObjectivesCompleted: number;
  goalLevelProgress: number;
  levelObjectivesMasteredPercent: number;
  levelObjectivesMasteredCount: number;
  levelObjectivesAttemptedCount: number;
  objectiveList: Objective[];
  studentUuid: string;
  timesCompleted: number;
  schoolsLastModifiedInMilliseconds: number;
  goalDetails: GoalDetails;
  totalLevelProgress: number;
  startingLevel: StartingLevelDetails;
  percentageDetails: PercentageDetails;
}

export interface PercentageDetails {
  currentLevel: CurrentLevelDetails;
}

export interface CurrentLevelDetails {
  currentLevelName: string;
  currentLevelPercent: number;
}
export interface StartingLevelDetails {
  startingLevelName: string;
  startingLevelPercent: number;
}

export interface GoalDetails {
  goalLevelKey: string;
  goalLevelName: string;
  goalLevelNumber: number;
  totalLevels: number;
}

export interface WacsDashboardsDocument {
  givenName: string;
  familyName: string;
  fall: BenchmarkStatus;
  winter: BenchmarkStatus;
  spring: BenchmarkStatus;
  uuid: string;
}

export interface WacsDashboardsClassDocument {
  name: string;
  grade: string;
  fall: BenchmarkClassStatus;
  winter: BenchmarkClassStatus;
  spring: BenchmarkClassStatus;
  uuid: string;
}

export interface WacsDashboardsSchoolDocument {
  name: string;
  organization: {
    uuid: string;
    name: string;
  };
  uuid: string;
  fall: BenchmarkClassStatus;
  winter: BenchmarkClassStatus;
  spring: BenchmarkClassStatus;
}

export interface WacsDashboardsDistrictDocument {
  name: string;
  uuid: string;
  fall: BenchmarkClassStatus;
  winter: BenchmarkClassStatus;
  spring: BenchmarkClassStatus;
}

export interface BenchmarkClassStatus {
  expired: number;
  notStarted: number;
  completed: number;
  inProgress: number;
  notAssigned: number;
}

export interface BenchmarkStatus {
  status: PeriodStatus;
  dueDate: string;
  overallGrade: string;
  overallLevel: string;
  overallLevelIncrease: number;
  overallScore: string;
  year: string;
}

export interface DashboardsClassroomDocument {
  name: string;
  onTrackPercent: number;
  onTrack: number;
  surpassingGoalPercent: number;
  surpassingGoal: number;
  actionTakenPercent: number;
  actionTaken: number;
  needsActionPercent: number;
  needsAction: number;
  objectivesMastered: number;
}

export interface DashboardsSchoolDocument {
  actionTakenPercent: number;
  name: string;
  needsActionPercent: number;
  objectivesMastered: number;
  onTrackPercent: number;
  actionTaken: number;
  goalStatuses: GoalStatus[];
  needsAction: number;
  onTrack: number;
  sumMastered: number;
  surpassingGoal: number;
  organization: {
    uuid: string;
    name: string;
  };
  sumGoalStatuses: number;
  surpassingGoalPercent: number;
  uuid: string;
}

export interface DashboardsDistrictDocument {
  actionTakenPercent: number;
  name: string;
  needsActionPercent: number;
  objectivesMastered: number;
  onTrackPercent: number;
  sumGoalStatuses: number;
  surpassingGoalPercent: number;
  actionTaken: number;
  goalStatuses: GoalStatus[];
  needsAction: number;
  onTrack: number;
  sumMastered: number;
  surpassingGoal: number;
  uuid: string;
}

export interface DashboardsFacetsResponse {
  facets: [ClassroomAnalyticsFacet, ...AnalyticsFacet[]];
  hits: DashboardsDocument[] | DashboardsClassroomDocument[];
}

export interface ParentHomeDocument {
  uuid: string;
  role: string;
  achievements: AchievementsDataSet;
  achievementsTimestampLastModifiedInMilliseconds: number;
  organization: {
    uuid: string;
    name: string;
  };
}

export interface AchievementsDataSet {
  [key: string]: ParentHomeAchievement;
}

export interface ParentHomeAchievement {
  days: {
    day: number;
    value: number;
    target: number;
  }[];
  schoolYearValue: number;
  schoolYearTarget: number;
}

export interface ParentHomeUsageCardData {
  title: string;
  weekDaysData: ParentHomeUsageCardWeekDayData[];
  schoolYearValueMinutes: number;
  schoolYearTargetMinutes: number;
}

export interface ParentHomeUsageCardWeekDayData {
  dayTimestamp: number;
  dayLabel: string;
  valueMinutes: number;
  targetMinutes: number;
  progressbarFilledPercentage: number;
  today: boolean;
  completed: boolean;
}
